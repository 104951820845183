import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export default function SuccessDisplay(props) {
  const router = useRouter();
  const { query } = router;
  const [email, setEmail] = useState(props.email);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [recorded, setRecorded] = useState(null);

  async function submitReport(eventId, name, email, description) {
    setRecorded(false);

    const n = await fetch(
      "https://sentry.io/api/0/projects/beatoven/webapp/user-feedback/",
      {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          event_id: eventId,
          name: name,
          email: email,
          comments: description,
        }),
        headers: {
          Authorization:
            "DSN https://439a81fcc6094d4780826875131b80e2@o937580.ingest.sentry.io/5887979",
          "Content-Type": "application/json",
        },
      }
    );
    setRecorded(true);
  }

  return (
    <div class="w-screen">
      {recorded === true ? (
        <div class="fixed flex text-white text-sm justify-center flex-col bg-[#242233] z-50 left-0 md:left-1/3 h-full right-0 top-0 md:h-72 text-center md:right-1/3 md:top-20 w-full md:w-auto p-6 rounded shadow-2xl">
          <p class="text-neutral-400 mb-4 text-base">
            Thank you! Your feedback has been recorded.
          </p>
          <button
            onClick={() => props.dismiss()}
            href="#"
            class="px-6 bg-green-400 rounded hover:bg-green-500 w-36 ml-auto mr-auto text-neutral-800 font-semibold py-3"
          >
            Close
          </button>
        </div>
      ) : (
        <div class="fixed flex text-white text-sm justify-center flex-col bg-[#242233] z-50 left-0 top-0 w-full h-full md:h-auto md:left-1/3 md:right-1/3 md:top-20 md:w-auto md:px-6 pt-0 px-6 md:pt-12 pb-8 rounded shadow-2xl">
          <button onClick={() => props.dismiss()}>
            <img
              src="/close.svg"
              alt="Close"
              data-tip="Close"
              className="absolute opacity-90 hover:opacity-100 top-5 right-5 w-5 cursor-pointer"
            />
          </button>
          <p class="text-neutral-400 text-base mb-5">
            Something clearly went wrong and we would like to know what. This
            will help us solve issues and make the product smoother for your
            use.
          </p>
          <div class="mb-4 w-full">
            <span class="mr-4 inline-block w-16">Email</span>
            <input
              class="input ml-auto p-2 text-white w-2/3 bg-transparent border-[1px] border-gray-600 rounded-md outline-none focus:border-gray-400"
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder=""
              type="text"
            />
          </div>
          <div class="mb-4 w-full">
            <span class="mr-4 inline-block w-16">Name</span>
            <input
              class="input p-2 text-white w-2/3 bg-transparent  border-[1px] border-gray-600 rounded-md outline-none focus:border-gray-400"
              placeholder=""
              onChange={(e) => setName(e.target.value)}
              type="text"
            />
          </div>
          <div class="w-full mb-4">
            <span class="mr-4 mb-2 block">Describe the issue</span>
            <textarea
              class="input p-2 bg-transparent text-white h-24 w-full border-[1px] border-gray-600 rounded-md outline-none focus:border-gray-400"
              onChange={(e) => setDescription(e.target.value)}
              placeholder="What happened? E.g. I clicked the download button and nothing happened!"
              type="textarea"
            />
          </div>
          <button
            onClick={() =>
              submitReport(props.eventId, name, email, description)
            }
            href="#"
            class={`px-6 bg-green-400 w-full rounded hover:bg-green-500 text-neutral-800 font-semibold py-3 ${
              recorded === false && "grayscale pointer-events-none select-none"
            }`}
          >
            {recorded === false ? "Submitting" : "Submit"}
          </button>
        </div>
      )}
      <div
        class="h-screen w-screen top-0 left-0 bg-neutral-600 backdrop-blur-md opacity-80 fixed z-10"
        onClick={() => props.dismiss()}
      ></div>
    </div>
  );
}
